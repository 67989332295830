import React from "react";
import Link from "next/link";
import { LinkedInIcon, TwitterIcon, GithubIcon } from "../Icons";
import { CustomFooterLink } from "../Links";
import Logo from "../../public/images/logo/logo-blue.png";
import Image from "next/image";

const Footer = () => {
  return (
    <footer className="w-full flex flex-col text-darkText dark:text-lightText -mt-16">
      <div className="flex flex-col items-center justify-between">
        <div className="flex flex-row justify-between font-medium pt-24 tablet:p-10 tablet:pt-24">
          <div className="ml-5 space-y-6">
            <nav className="flex flex-col gap-5">
              <CustomFooterLink href="/" title="Home" />
              <CustomFooterLink href="/about" title="About" />
              <CustomFooterLink href="/portfolio" title="Portfolio" />
              <CustomFooterLink href="/services" title="Services" />
              <CustomFooterLink href="/contact" title="Contact" />
            </nav>
            <nav className="flex items-center justify-center">
              <Link
                href="https://twitter.com/thetalesofj"
                aria-label="Twitter Link"
                target={"_blank"}
                className="mr-4 text-darkText dark:text-lightText hover:text-secondaryLight dark:hover:text-secondaryDark"
              >
                <TwitterIcon />
              </Link>
              <Link
                href="https://www.linkedin.com/in/jeremiah-haastrup/"
                aria-label="LinkedIn Link"
                target={"_blank"}
                className="mx-4 text-darkText dark:text-lightText hover:text-secondaryLight dark:hover:text-secondaryDark"
              >
                <LinkedInIcon />
              </Link>
              <Link
                href="https://github.com/jeremiahhaastrup"
                target={"_blank"}
                aria-label="Github Link"
                className="ml-4 text-darkText dark:text-lightText hover:text-secondaryLight dark:hover:text-secondaryDark"
              >
                <GithubIcon />
              </Link>
            </nav>
          </div>
          <div className="w-1/2 mobile:w-1/3 desktop:w-1/4">
            <Image
              src={Logo}
              alt="Logo"
              className="dark:grayscale dark:brightness-[4]"
            />
          </div>
        </div>
        <span className="pb-5 pt-10 text-center text-sm">
          {new Date().getFullYear()} &copy; Jeremiah Haastrup, All Rights
          Reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
