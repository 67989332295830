import type { AppProps } from "next/app";
import Footer from "@/components/ui/Footer";
import NavBar from "@/components/ui/NavBar";
import "@/styles/globals.css";
import localFont from "next/font/local";
import Head from "next/head";
import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import Image from "next/image";
import TopFooter from "../public/images/background/curve.png";
import { Analytics } from "@vercel/analytics/react";

const inter = localFont({
  src: [
    {
      path: "../public/fonts/Inter/Inter-Thin.ttf",
      weight: "100",
      style: "normal",
    },
    {
      path: "../public/fonts/Inter/Inter-ExtraLight.ttf",
      weight: "200",
      style: "normal",
    },
    {
      path: "../public/fonts/Inter/Inter-Light.ttf",
      weight: "300",
      style: "normal",
    },
    {
      path: "../public/fonts/Inter/Inter-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/fonts/Inter/Inter-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../public/fonts/Inter/Inter-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../public/fonts/Inter/Inter-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../public/fonts/Inter/Inter-ExtraBold.ttf",
      weight: "800",
      style: "normal",
    },
    {
      path: "../public/fonts/Inter/Inter-Black.ttf",
      weight: "900",
      style: "normal",
    },
  ],
  variable: "--font-inter",
});

const sofia = localFont({
  src: [
    {
      path: "../public/fonts/Sofia/SofiaSansExtraCondensed-Thin.ttf",
      weight: "100",
      style: "normal",
    },
    {
      path: "../public/fonts/Sofia/SofiaSansExtraCondensed-Light.ttf",
      weight: "300",
      style: "normal",
    },
    {
      path: "../public/fonts/Sofia/SofiaSansExtraCondensed-Regular.ttf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/fonts/Sofia/SofiaSansExtraCondensed-Medium.ttf",
      weight: "500",
      style: "normal",
    },
    {
      path: "../public/fonts/Sofia/SofiaSansExtraCondensed-SemiBold.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../public/fonts/Sofia/SofiaSansExtraCondensed-Bold.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../public/fonts/Sofia/SofiaSansExtraCondensed-ExtraBold.ttf",
      weight: "800",
      style: "normal",
    },
    {
      path: "../public/fonts/Sofia/SofiaSansExtraCondensed-Black.ttf",
      weight: "900",
      style: "normal",
    },
  ],
  variable: "--font-sofia",
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width-device-width, initial-scale=1, viewport-fit=cover"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link rel="icon" href="/logo.png" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="google-site-verification"
          content="dlNDtSuXHSNulfPuDCA1D-DCOFE5OdplkiZkypH4WgI"
        />
        <meta name="yandex-verification" content="d58d676f12fda460" />
        <meta name="robots" content="all" />
      </Head>
      <main
        className={`${inter.variable} ${sofia.variable} font-sans bg-lightBackground dark:bg-darkBackground w-full min-h-screen`}
      >
        <NavBar />
        <AnimatePresence mode="wait">
          <Component key={router.asPath} {...pageProps} />
        </AnimatePresence>
        <Analytics />
        <Image
          src={TopFooter}
          className="pt-10 invert brightness-50 dark:grayscale dark:invert-0 dark:brightness-100"
          alt=""
        />
        <Footer />
      </main>
    </>
  );
}
