import React, { useEffect, useState } from 'react';

const UseTheme = () => {
  
  const darkQuery = '(prefers-color-scheme: dark)';
  const [mode, setMode] = useState('');

  useEffect(() => {
    const mediaQuery = window.matchMedia(darkQuery);
    const userPref = window.localStorage.getItem('theme');

    const handleChange = () => {
      if (userPref) {
        const newMode = userPref === 'dark' ? 'dark' : 'light';
        setMode(newMode);
        if (newMode === 'dark') {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      } else {
        let newMode = mediaQuery.matches ? 'dark' : 'light';
        setMode(newMode);
        window.localStorage.setItem('theme', newMode)
        if (newMode === 'dark') {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      }
    }
    handleChange();

    mediaQuery.addEventListener('change', handleChange);

    return () => 
      mediaQuery.removeEventListener('change', handleChange);
    
  }, []);

  useEffect(() => {
    if (mode === 'dark') {
      window.localStorage.setItem('theme', 'dark');
      document.documentElement.classList.add('dark');
    } else {
      window.localStorage.setItem('theme', 'light');
      document.documentElement.classList.remove('dark');
    }
  }, [mode]);

  return {mode, setMode};
};

export default UseTheme;
