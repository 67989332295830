import React, { useState, useEffect } from "react";
import Link from "next/link";
import Logo from "../Logo";
import {
  GithubIcon,
  LinkedInIcon,
  MoonIcon,
  SunIcon,
  TwitterIcon,
} from "../Icons";
import { motion } from "framer-motion";
import { CustomNavLink, CustomLink } from "../Links";
import UseTheme from "../hooks/UseTheme";

const NavBar = () => {
  const { mode, setMode } = UseTheme();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsOpen(false);
      document.body.style.overflowY = "auto";
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const LinkTransition = ({ children, duration }: any) => {
    return (
      <motion.div
        initial={{ x: "100%", opacity: 0 }}
        whileInView={{ x: "0%", opacity: 1 }}
        transition={{ duration: duration, type: "spring" }}
        exit={{ x: "100%", opacity: 0 }}
      >
        {children}
      </motion.div>
    );
  };

  return (
    <header className="sticky top-0 w-full px-10 py-8 desktop:py-5 z-50 bg-lightBackground dark:bg-darkBackground flex items-center font-medium space-x-3">
      <div className="flex items-center justify-between w-5/6">
        <div className="w-1/3 laptop:w-1/4 desktop:w-1/6">
          <Logo mode={mode} />
        </div>
        <button
          className="flex flex-col justify-center items-center laptop:hidden"
          aria-label="Navigation button"
          onClick={handleClick}
        >
          <span
            className={`bg-darkBackground dark:bg-lightBackground block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm -translate-y-0.5 ${
              isOpen ? "rotate-45 translate-y-1" : "-translate-y-0.5"
            }`}
          ></span>
          <span
            className={`bg-darkBackground dark:bg-lightBackground block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm my-0.5 ${
              isOpen ? "opacity-0" : "opacity-100"
            }`}
          ></span>
          <span
            className={`bg-darkBackground dark:bg-lightBackground block transition-all duration-300 ease-out h-0.5 w-6 rounded-sm -translate-y-0.5 ${
              isOpen ? "-rotate-45 -translate-y-1" : "translate-y-0.5"
            }`}
          ></span>
        </button>
        {isOpen ? (
          <div className="w-screen h-screen flex flex-col items-center justify-between fixed overflow-y-auto z-30 top-[6rem] largeMobile:top-[8rem] left-0 tablet:top-[9.5rem] landscape:top-[9.9rem] laptop:hidden bg-lightBackground dark:bg-darkBackground">
            <motion.nav
              className="flex flex-col items-center landscape:space-y-6 space-y-12 justify-center pt-5"
              initial={{ x: "100%", opacity: 0 }}
              whileInView={{ x: "0%", opacity: 1 }}
              transition={{ duration: 1, type: "spring" }}
              exit={{ x: "100%", opacity: 0 }}
            >
              <LinkTransition duration={1.2}>
                <CustomNavLink toggle={handleClick} href="/" title="Home" />
              </LinkTransition>
              <LinkTransition duration={1.4}>
                <CustomNavLink
                  toggle={handleClick}
                  href="/about"
                  title="About"
                />
              </LinkTransition>
              <LinkTransition duration={1.6}>
                <CustomNavLink
                  toggle={handleClick}
                  href="/portfolio"
                  title="Portfolio"
                />
              </LinkTransition>
              <LinkTransition duration={1.8}>
                <CustomNavLink
                  toggle={handleClick}
                  href="/services"
                  title="Services"
                />
              </LinkTransition>
              <LinkTransition duration={2}>
                <CustomNavLink
                  toggle={handleClick}
                  href="/contact"
                  title="Contact"
                />
              </LinkTransition>
            </motion.nav>
            <nav className="flex items-center justify-center pt-4 pb-[13rem]">
              <Link
                href="https://twitter.com/thetalesofj"
                aria-label="Twitter Link"
                target={"_blank"}
                className="mr-4 text-darkText dark:text-lightText hover:text-primaryLight dark:hover:text-primaryDark"
              >
                <TwitterIcon />
              </Link>
              <Link
                href="https://www.linkedin.com/in/jeremiah-haastrup/"
                aria-label="LinkedIn Link"
                target={"_blank"}
                className="mx-4 text-darkText dark:text-lightText hover:text-primaryLight dark:hover:text-primaryDark"
              >
                <LinkedInIcon />
              </Link>
              <Link
                href="https://github.com/jeremiahhaastrup"
                target={"_blank"}
                aria-label="Github Link"
                className="ml-4 text-darkText dark:text-lightText hover:text-primaryLight dark:hover:text-primaryDark"
              >
                <GithubIcon />
              </Link>
            </nav>
          </div>
        ) : null}
        <nav className="max-laptop:hidden">
          <CustomLink href="/" title="Home" className="mr-4" />
          <CustomLink href="/about" title="About" className="mx-4" />
          <CustomLink href="/portfolio" title="Portfolio" className="mx-4" />
          <CustomLink href="/services" title="Services" className="mx-4" />
          <CustomLink href="/contact" title="Contact" className="ml-4" />
        </nav>
      </div>
      <div className="w-1/6 flex items-center justify-center">
        <motion.button
          onClick={() => setMode(mode === "light" ? "dark" : "light")}
          className="text-xl flex ml-3 items-center justify-center rounded-full p-2 border-2 border-darkBackground dark:border-lightBackground"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.95 }}
        >
          {mode === "dark" ? (
            <div className="text-lightBackground">
              <MoonIcon />
            </div>
          ) : (
            <div className="text-darkBackground">
              <SunIcon />
            </div>
          )}
        </motion.button>
      </div>
    </header>
  );
};

export default NavBar;
