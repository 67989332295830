import React from 'react';
import Image from 'next/image';
import Light_Logo from '../public/images/logo/logo-blue.png';
import Dark_Logo from '../public/images/logo/logo-grey.png';
import Link from 'next/link';
import { motion } from 'framer-motion';

interface Props {
  mode: string;
}

const Logo = ({ mode }: Props) => {
  const logoSrc = mode === 'dark' ? Dark_Logo : Light_Logo;

  return (
    <motion.div
    whileHover={{scale:1.1}}
    whileTap={{scale:0.9}}
    >
      <Link href="/">
        <Image priority src={logoSrc} alt="Company logo" />
      </Link>
    </motion.div>
  );
};

export default Logo;
